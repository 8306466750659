<template>
  <!-- 首页 -->
  <div class="home-box">
    <div class="home-left-box">
      <div class="tltle-box">
        <!-- 组织结构 -->
        <el-input class="refresh-ipt" placeholder="搜索" v-model="filterText">
        </el-input>
        <!-- 选择了那些人 -->
        <!-- <div class="refresh-box">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-video-camera-solid"
            circle
            @click="OnSelect"
          ></el-button>
        </div> -->
        <!-- 刷新树 -->
        <div class="refresh-box">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-refresh"
            circle
            @click="OnRefreshTree"
          ></el-button>
        </div>
      </div>
      <el-tree
        class="tree-box"
        :data="treeData"
        node-key="level"
        :default-expand-all="false"
        :expand-on-click-node="false"
        :props="treeProps"
        :default-expanded-keys="currentKey"
        :accordion="true"
        ref="tree"
        :filter-node-method="filterNode"
        show-checkbox
      >
        <!-- @node-click="handleNodeClick" -->
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <img
              v-if="data.avatar"
              style="width: 20px; height: 20px"
              :src="data.avatar"
              alt=""
              @click="onContacts(data)"
              class="mr-10"
            />
            {{ node.label }}
          </span>
          <!-- 常用联系人 -->
          <span v-if="data.level == '1_0'">
            <el-button type="text" size="mini" @click="() => append(data)">
              <img
                style="width: 20px; height: 20px"
                src="../../assets/home/add.png"
                alt=""
              />
            </el-button>
          </span>
          <!-- 自定义组 -->
          <div
            v-if="data.is_del == '1'"
            style="display: flex; align-items: center"
          >
            <el-popover
              placement="right"
              trigger="hover"
              popper-class="tree-popper"
              width="160"
            >
              <p>确定删除吗？</p>
              <div style="text-align: right; margin: 0">
                <!-- <el-button size="mini" type="text" @click="visible = false"
                  >取消</el-button
                > -->
                <el-button type="primary" size="mini" @click="delGrouping(data)"
                  >确定</el-button
                >
              </div>
              <div slot="reference">
                <img
                  style="width: 20px; height: 20px"
                  src="../../assets/home/del.png"
                  alt=""
                />
                <!-- <img src="../../assets/home/more_2.png" alt="" /> -->
              </div>
            </el-popover>
            <el-button
              style="margin-left: 10px"
              type="text"
              size="mini"
              @click="() => edit(data)"
            >
              <img
                style="width: 15px; height: 15px"
                src="../../assets/home/edit.png"
                alt=""
              />
            </el-button>
          </div>
          <!-- 联系人 -->
          <span v-if="data.status">
            <img
              v-if="data.status == 2"
              style="width: 20px; height: 20px"
              src="../../assets/home/xing.png"
              alt=""
              @click="onContacts(data)"
            />
            <img
              v-else
              style="width: 20px; height: 20px"
              src="../../assets/home/xing1.png"
              alt=""
              @click="onContacts(data)"
            />

            <!-- <el-popover
              placement="right"
              trigger="hover"
              popper-class="tree-popper"
            >
              <div class="popover-content-box">
                <el-button
                  v-if="[1].includes(node.level)"
                  size="mini"
                  type="text"
                  @click="add(node, data)"
                  >添加</el-button
                >
                <el-button size="mini" type="text" @click="edit(node, data)"
                  >编辑</el-button
                >
                <el-button size="mini" type="text" @click="del(node, data)"
                  >删除</el-button
                >
              </div>
              <div slot="reference" class="backend-btn">
                <img src="../../assets/home/more_2.png" alt="" />
              </div>
            </el-popover> -->
          </span>
        </span>
      </el-tree>
    </div>
    <div class="home-right-box">
      <div class="top-box">
        <div class="child" @click="joinMeeting">
          <img src="@/assets/home/add-fill.png" alt="" />
          <span>加入会议</span>
        </div>
        <div class="child" @click="predetermineMeeting()">
          <img src="@/assets/home/predetermine.png" alt="" />
          <span>预定会议</span>
        </div>
        <div class="child" @click="quickMeeting">
          <img src="@/assets/home/flashlight-fill.png" alt="" />
          <span>快速会议</span>
        </div>
      </div>
      <div class="bottom-box">
        <div class="bottom-title">
          <div>会议列表</div>
          <div class="history-btn-box" @click="HistoryMeeting()">历史会议</div>
        </div>
        <div class="search-box">
          <CustomSearchForm
            ref="searchForm"
            class="right-top-box"
            :formConfig="formConfig"
            :formData="formData"
            :form="form"
          />
        </div>
        <div class="bottom-main-box">
          <!-- 进行中 -->
          <div class="bottom-left-box">
            <div class="small-title-box">
              进行中
              <el-badge
                v-if="inMeeting.length > 0"
                :value="inMeeting.length"
                class="item"
              >
              </el-badge>
            </div>
            <div class="items-box" v-if="inMeeting.length > 0">
              <div
                class="item-box"
                v-for="(item, index) in inMeeting"
                :key="index"
              >
                <div class="item-top-box">
                  <div class="item-title-box">
                    <!-- {{ item.title }} -->
                    <GlobalAutoTooltip
                      :content="item.title"
                      placement="bottom"
                    />
                  </div>
                  <div style="display: flex">
                    <div
                      v-if="item.is_attend_meeting == 1"
                      class="item-status-box"
                      style="margin-right: 10px"
                    >
                      已入会
                    </div>
                    <div class="item-status-box">{{ item.status_name }}</div>
                  </div>
                </div>
                <div class="item-center-box">{{ item.start_time }}</div>
                <div class="item-btn-box">
                  <!-- <span
                    style="cursor: pointer; color: rgba(220, 12, 12, 1)"
                    @click="onEndMeeting(item)"
                    >结束会议</span
                  > -->
                  <el-popover
                    placement="top"
                    width="160"
                    v-model="item.delVisible"
                    popper-class="table-del-popper"
                  >
                    <p>确定结束会议？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="item.delVisible = false"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="onEndMeeting(item)"
                        >确定</el-button
                      >
                    </div>
                    <div
                      v-if="user_id == item.user_id"
                      slot="reference"
                      style="cursor: pointer; color: rgba(220, 12, 12, 1)"
                    >
                      结束会议
                    </div>
                  </el-popover>
                  <span
                    v-if="user_id == item.user_id"
                    style="cursor: pointer; color: rgba(45, 140, 255, 1)"
                    @click="onInvite(item)"
                    >分享</span
                  >
                  <span
                    style="cursor: pointer; color: rgba(45, 140, 255, 1)"
                    @click="onJoin(item)"
                    >入会</span
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <img class="no-meet-img" src="@/assets/home/no-meet.png" alt="" />
              <span class="no-meet-text">暂无会议</span>
            </div>
          </div>
          <el-divider
            direction="vertical"
            content-position="center"
          ></el-divider>
          <!-- 待开始 -->
          <div class="bottom-right-box">
            <div class="small-title-box">
              待开始
              <el-badge
                v-if="waitMeeting.length > 0"
                :value="waitMeeting.length"
                class="item"
              >
              </el-badge>
            </div>
            <div class="items-box" v-if="waitMeeting.length > 0">
              <div
                class="item-box"
                v-for="(item, index) in waitMeeting"
                :key="index"
              >
                <div class="item-top-box">
                  <div class="item-title-box">
                    <!-- {{ item.title }} -->
                    <GlobalAutoTooltip
                      :content="item.title"
                      placement="bottom"
                    />
                    <img
                      v-if="user_id == item.user_id"
                      class="title-edit-img"
                      src="@/assets/home/edit.png"
                      alt="编辑"
                      @click="onEditMeeting(item)"
                    />
                  </div>
                  <div style="display: flex">
                    <div
                      v-if="item.is_attend_meeting == 1"
                      class="item-status-box"
                      style="margin-right: 10px"
                    >
                      已入会
                    </div>
                    <div class="item-status1-box">{{ item.status_name }}</div>
                  </div>
                </div>
                <div class="item-center-box">{{ item.start_time }}</div>
                <div class="item-btn-box">
                  <!-- <span
                    style="cursor: pointer; color: rgba(220, 12, 12, 1)"
                    @click="onDel(item)"
                    >取消会议</span
                  > -->
                  <el-popover
                    placement="top"
                    width="160"
                    v-model="item.delVisible"
                    popper-class="table-del-popper"
                  >
                    <p>确定取消会议？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="item.delVisible = false"
                        >取消</el-button
                      >
                      <el-button type="primary" size="mini" @click="onDel(item)"
                        >确定</el-button
                      >
                    </div>
                    <div
                      v-if="user_id == item.user_id"
                      slot="reference"
                      style="cursor: pointer; color: rgba(220, 12, 12, 1)"
                    >
                      取消会议
                    </div>
                  </el-popover>
                  <span
                    v-if="user_id == item.user_id"
                    style="cursor: pointer; color: rgba(45, 140, 255, 1)"
                    @click="onInvite(item)"
                    >分享</span
                  >
                  <span
                    style="cursor: pointer; color: rgba(45, 140, 255, 1)"
                    @click="onJoin(item)"
                    >入会</span
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <img class="no-meet-img" src="@/assets/home/no-meet.png" alt="" />
              <span class="no-meet-text">暂无会议</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <!-- :fullscreen="true" -->
      <AppVideo />
      <!-- <span>这是一段信息</span> -->
      <!-- <span slot="title" class="dialog-footer"><div></div></span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 加入会议 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <JoinMeeting
        v-if="joinMeetingdialogOptions.visible"
        :options="joinMeetingdialogOptions"
        @onJoinClose="onJoinClose"
      />
    </transition>
    <!-- 预定会议 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <PredetermineMeeting
        v-if="predetermineMeetingdialogOptions.visible"
        :options="predetermineMeetingdialogOptions"
        @onYDClose="onYDClose"
        @onEditClose="onEditClose"
        :row="currentRow"
      />
    </transition>
    <!-- 等候室 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <WaitingRoom
        v-if="waitingRoomdialogOptions.visible"
        :options="waitingRoomdialogOptions"
        :list="waitRoomlist"
        @confirm="onInviteJoin"
        @close="onInviteClose"
      />
    </transition>
    <!-- 快速会议 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <QuickMeeting
        v-if="QuickMeetingdialogOptions.visible"
        :options="QuickMeetingdialogOptions"
        :QuickMeetingProps="QuickMeetingProps"
        @onKSClose="onKSClose"
      />
      <!-- :quickMeetingRoomNumber="quickMeetingRoomNumber"
        :quickMeetingRoomTitle="quickMeetingRoomTitle" -->
    </transition>
    <!-- 分享会议 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <ShareMeeting
        v-if="shareMeetingdialogOptions.visible"
        :options="shareMeetingdialogOptions"
        @onFXClose="onFXClose"
        :row="currentRow"
      />
    </transition>
    <!-- 收藏联系人 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <ContactsDialog
        v-if="contactsdialogOptions.visible"
        :options="contactsdialogOptions"
        @onContactsClose="onContactsClose"
      />
    </transition>
  </div>
</template>

<script>
import CustomSearchForm from "@/components/custom/CustomSearchForm";
import AppVideo from "@/AppVideo.vue";
import {
  shuList, //树
  contacts, //收藏
  quickMeeting, //快速会议
  meetingAdd, //预定会议
  meetingList, //会议列表
  statusList,
  meetingWaitingArea,
  meetingEdit, //修改预定会议
  meetingDel, //取消预定会议
  joinMeeting, //加入会议
  endMeeting, //结束会议
  endMeeting1, //列表结束会议
} from "@/api/home.js";
import { addGrouping, udpGrouping, delGrouping } from "@/api/user.js";

import JoinMeeting from "./components/JoinMeeting.vue";
import PredetermineMeeting from "./components/PredetermineMeeting.vue";
import WaitingRoom from "./components/WaitingRoom.vue";
import QuickMeeting from "./components/QuickMeeting.vue";
import ShareMeeting from "./components/ShareMeeting.vue";
import ContactsDialog from "./components/ContactsDialog.vue";
export default {
  components: {
    CustomSearchForm,
    AppVideo,
    JoinMeeting, //加入会议
    PredetermineMeeting, //预定会议
    WaitingRoom, // 等候室
    QuickMeeting, //快速会议
    ShareMeeting, //分享会议
    ContactsDialog, //收藏联系人
  },
  data() {
    return {
      user_id: JSON.parse(localStorage.getItem("userInfo")).id,
      nickname: JSON.parse(localStorage.getItem("userInfo")).nickname,
      waitRoomlist: [],
      dialogVisible: false,
      // 搜索字段
      visible: false,
      filterText: "",
      treeProps: { label: "name" },
      // 当前展开那个节点
      currentKey: [],
      // 左侧树
      treeData: [],
      //收藏联系人弹窗
      contactsdialogOptions: {
        data: {},
        title: "收藏联系人",
        visible: false,
        disabled: false,
        // size: "small-dialog-box",
        size: "mini-dialog-box",
      },
      // 进行中会议列表
      inMeeting: [],
      // 预定会议列表
      waitMeeting: [],
      // 显示加入会议弹窗
      joinMeetingdialogOptions: {
        title: "",
        visible: false,
        disabled: false,
        size: "small-dialog-box",
      },
      // 显示预定会议弹窗
      predetermineMeetingdialogOptions: {
        title: "",
        visible: false,
        disabled: false,
        size: "small-dialog-box",
      },
      // 显示等候室弹窗
      waitingRoomdialogOptions: {
        title: "",
        visible: false,
        disabled: false,
        number: "",
        isInMeeting: false,
        meetInfo: null,
        size: "small-dialog-box",
      },
      // 显示快速会议弹窗
      QuickMeetingdialogOptions: {
        title: "",
        visible: false,
        disabled: false,
        // size: "small-dialog-box",
      },
      QuickMeetingProps: {
        // 快速会议房间号
        quickMeetingRoomNumber: "",
        //快速会议房间名称
        quickMeetingRoomTitle: "",
        // 昵称
        nickname: "",
        //是否开启摄像头
        is_camera: false,
        //是否开启麦克风
        is_speech: false,
        //是否开启等候室
        waiting_room: false,
        // 会议创建人
        user_id: "",
      },
      // 分享会议弹窗
      shareMeetingdialogOptions: {
        title: "邀请",
        visible: false,
        disabled: false,
        footerShow: false, //是否显示底部按钮
        size: "small-dialog-box",
      },
      // 当前修改的预定会议
      currentRow: null,

      //新增搜索功能
      formConfig: {
        props: {
          labelPosition: "right",
          inline: true,
          class: "microseismic-middle-form",
          labelWidth: "80px",
        },
        evens: {},
      },
      form: {
        // type: 1,
        meeting_name: "",
        meeting_number: "",
        promoter: "",
      },
      formData: [
        // {
        //   formItemProps: {
        //     label: "筛选类型",
        //   },
        //   type: "el-select",
        //   key: "type",
        //   itemProps: {
        //     placeholder: "请选择",
        //   },
        //   labelKey: "name",
        //   valueKey: "key",
        //   options: [
        //     { name: "进行中", key: 1 },
        //     { name: "待开始", key: 2 },
        //   ],
        //   itemEvents: {},
        // },
        {
          formItemProps: {
            label: "会议名称",
          },
          type: "el-input",
          key: "meeting_name",
          itemProps: {
            placeholder: "请输入会议名称",
          },
          itemEvents: {
            // change: this.airTypeChange,
          },
        },
        {
          formItemProps: {
            label: "会议号",
          },
          type: "el-input",
          key: "meeting_number",
          itemProps: {
            placeholder: "请输入会议号",
          },
          itemEvents: {
            // change: this.airTypeChange,
          },
        },
        {
          formItemProps: {
            label: "发起人",
          },
          type: "el-input",
          key: "promoter",
          itemProps: {
            placeholder: "请输入发起人",
          },
          itemEvents: {
            // change: this.airTypeChange,
          },
        },
        {
          formItemProps: {},
          type: "el-button",
          itemProps: {
            buttonName: "重置",
            class: "refresh",
            icon: "custom-icon-refresh",
          },
          itemEvents: {
            click: this.reset,
          },
        },
        {
          formItemProps: {},
          type: "el-button",
          itemProps: {
            buttonName: "搜索",
            class: "search",
            icon: "custom-icon-search",
          },
          itemEvents: {
            click: this.getData,
          },
        },
        //刷新
        // {
        //   formItemProps: {},
        //   type: "el-button",
        //   itemProps: {
        //     buttonName: "刷新",
        //     class: "search",
        //     icon: "custom-icon-search",
        //   },
        //   itemEvents: {
        //     click: this.refresh,
        //   },
        // },
      ],
    };
  },
  watch: {
    filterText(val) {
      console.log("🚀 ~ filterText ~ val:", val);
      this.$refs.tree.filter(val);
    },
  },
  computed: {},
  created() {
    this.init();
    this.$utils.bus.$on("onJoinmeet", this.onJoin);
  },
  beforeDestroy() {
    this.clearTimer();
    this.$utils.bus.$off("onJoinmeet", this.onJoin);
  },
  methods: {
    // test(val) {
    //   console.log("🚀 ~ test ~ val:", val);
    // },
    add(node, data) {},
    //过滤树
    filterNode(value, data, node) {
      // console.log(
      //   "🚀 ~ filterNode ~ node:",
      //   node,
      //   node.parent,
      // );
      // console.log("🚀 ~ filterNode ~ data:", data);
      // console.log("🚀 ~ filterNode ~ value:", value);
      // if (!value) return true;
      // console.log(
      //   "🚀 ~ filterNode ~ data.name.indexOf(value) !== -1;:",
      //   data.name.indexOf(value)
      // );
      if (!value) {
        node.expanded = false;
        return true;
      }
      return this.filterNodeData(value, data, node);
    },
    // 搜索到父节点将返回所有子节点
    filterNodeData(value, data, node) {
      if (data.name.indexOf(value) !== -1) {
        return true;
      }
      const level = node.level;
      // 如果传入的节点本身就是一级节点就不用校验了
      if (level === 1) {
        return false;
      }
      // 先取当前节点的父节点
      let parentData = node.parent;
      // 遍历当前节点的父节点
      let index = 0;
      while (index < level - 1) {
        // 如果匹配到直接返回
        if (parentData.data.name.indexOf(value) != -1) {
          return true;
        }
        // 否则的话再往上一层做匹配
        parentData = parentData.parent;
        index++;
      }
      // 没匹配到返回false
      return false;
    },
    //添加分组
    append(data) {
      console.log("🚀 ~ append ~ data:", data);
      this.$prompt("请输入分组名称", "添加", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[a-zA-Z0-9\u4e00-\u9fa5]{1,5}$/,
        inputErrorMessage: "请输入1到5个字",
      })
        .then(({ value }) => {
          console.log("🚀 ~ .then ~ value:", value);

          const params = {
            name: value,
          };
          addGrouping(params).then((res) => {
            this.$message.success(res.msg);
            this.$nextTick(() => {
              this.currentKey = [];
              this.currentKey.push("1_0");
            });
            this.getTreeList();
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "取消输入",
          // });
        });
    },
    //修改分组
    edit(data) {
      console.log("🚀 ~ edit ~ data:", data);
      this.$prompt("请输入分组名称", "编辑", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[a-zA-Z0-9\u4e00-\u9fa5]{1,5}$/,
        inputErrorMessage: "请输入1到5个字",
      })
        .then(({ value }) => {
          console.log("🚀 ~ .then ~ value:", value);

          const params = {
            id: data.id,
            name: value,
          };
          udpGrouping(params).then((res) => {
            this.$message.success(res.msg);
            this.$nextTick(() => {
              this.currentKey = [];
              this.currentKey.push(data.level);
            });
            this.getTreeList();
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "取消输入",
          // });
        });
    },
    //删除分组
    delGrouping(data) {
      console.log("🚀 ~ delGrouping ~ data:", data);
      delGrouping({ id: data.id }).then((res) => {
        this.$message.success(res.msg);
        this.$nextTick(() => {
          this.currentKey = [];
          this.currentKey.push("1_0");
        });
        this.getTreeList();
      });
    },

    remove(node, data) {
      console.log("🚀 ~ remove ~ node, data:", node, data);
      // const parent = node.parent;
      // const children = parent.data.children || parent.data;
      // const index = children.findIndex((d) => d.id === data.id);
      // children.splice(index, 1);
    },
    init() {
      //获取左侧树
      this.getTreeList();
      // 获取会议列表
      this.getStatusList();

      this.clearTimer();
      this.timer = setInterval(() => {
        this.getStatusList();
      }, window.g.intervalTime || 3000);
    },
    clearTimer() {
      this.timer && clearInterval(this.timer);
      // console.log("已清理两个会议定时请求");
    },
    //获取左侧树
    getTreeList() {
      shuList().then((res) => {
        this.treeData = res.data;
      });
    },
    getStatusList() {
      const params = {
        ...this.form,
      };
      statusList(params).then((res) => {
        console.log("🚀 ~ statusList ~ res:", res);
        // 进行中
        this.inMeeting = res.data.status1;
        // 预定中
        this.waitMeeting = res.data.status0;
      });
    },
    // 选择那些人
    OnSelect() {
      this.getCheckedNodes();
      this.getCheckedKeys();
    },
    getCheckedNodes() {
      console.log(
        this.$refs.tree.getCheckedNodes(),
        "获取node对象---------------------------------"
      );
    },
    getCheckedKeys() {
      console.log(
        this.$refs.tree.getCheckedKeys(),
        "获取节点---------------------------------"
      );
    },
    // 刷新树
    OnRefreshTree() {
      this.filterText = "";
      this.getTreeList();
    },
    // 收藏取消联系人
    onContacts(data) {
      console.log("🚀 ~ onContacts ~ data:", data);
      //1已收藏，2未收藏
      if (data.status == 1) {
        contacts({ lx_user_id: data.id }).then((res) => {
          this.$nextTick(() => {
            this.currentKey = [];
            this.currentKey.push(data.level);
          });
          this.getTreeList();
        });
      } else {
        //显示弹窗，下拉选择分组
        //联系人id
        this.contactsdialogOptions.data = data;
        this.contactsdialogOptions.visible = true;
      }
      // contacts({ lx_user_id: data.id }).then((res) => {
      //   this.getTreeList();
      // });
    },
    //关闭收藏
    onContactsClose(form) {
      if (form) {
        const params = {
          lx_user_id: this.contactsdialogOptions.data.id,
          grouping_id: form.grouping_id,
        };
        console.log("🚀 ~ onContactsClose ~ form:", form);
        contacts(params).then((res) => {
          this.$nextTick(() => {
            this.currentKey = [];
            this.currentKey.push(this.contactsdialogOptions.data.level);
          });
          this.contactsdialogOptions.visible = false;
          this.getTreeList();
        });
      } else {
        this.contactsdialogOptions.visible = false;
      }
    },
    // 获取进行会议列表
    getJXMeeting() {
      const params = {
        status: 1,
        ...this.form,
      };
      meetingList(params).then((res) => {
        this.inMeeting = res.data;
      });
    },
    // 获取预定会议列表
    getYDMeeting() {
      const params = {
        status: 0,
        ...this.form,
      };
      meetingList(params).then((res) => {
        this.waitMeeting = res.data;
      });
    },

    // 加入会议
    joinMeeting() {
      console.log("加入会议");
      this.joinMeetingdialogOptions.title = "加入会议";
      this.joinMeetingdialogOptions.visible = true;
    },
    // 加入会议 填完信息确认
    onJoinClose(form) {
      if (form) {
        joinMeeting(form).then((res) => {
          console.log("🚀 ~ joinMeeting ~ res:111111111111111111", res);
          // && res.data.waiting_room == false
          if (res.data.number && res.data.title && !res.data.waiting_room) {
            this.QuickMeetingProps.quickMeetingRoomNumber = res.data.number;
            this.QuickMeetingProps.quickMeetingRoomTitle = res.data.title;
            this.QuickMeetingProps.start_time = this.$Moment(
              res.data.start_time * 1000
            ).format("yyyy-MM-DD HH:mm");
            this.QuickMeetingProps.nickname = form.nickname;
            this.QuickMeetingProps.is_camera = form.is_camera;
            this.QuickMeetingProps.is_speech = form.is_speech;
            this.QuickMeetingProps.user_id = res.data.user_id;
            this.joinMeetingdialogOptions.visible = false;
            this.QuickMeetingdialogOptions.visible = true;
          } else if (
            res.data.number &&
            res.data.title &&
            res.data.waiting_room
          ) {
            // 加入等候室，关闭当前弹窗
            this.joinMeetingdialogOptions.visible = false;
            this.onJoin(res.data);
          } else {
            this.$message({
              type: "info",
              message: "创建失败",
            });
          }
        });
      } else {
        this.currentRow = null;
        this.joinMeetingdialogOptions.visible = false;
      }
    },
    // 等候室邀请入会
    onInviteJoin(item) {
      console.log("🚀 ~ onInviteJoin ~ item:", item);
      console.group("等候室邀请入会信息", item.user_id, this.user_id);
      // let urlList = item.url.split("/");
      // const roomNumber = urlList[urlList.length - 1];
      const roomNumber = item.number;
      const params = {
        number: roomNumber,
        nickname: item.founder_nickname,
        is_camera: 2,
        is_speech: 2,
      };
      joinMeeting(params).then((res) => {
        console.log("🚀 ~ joinMeeting ~ res:", res);
        if (res.data.number && res.data.title) {
          this.QuickMeetingProps.quickMeetingRoomNumber = res.data.number;
          this.QuickMeetingProps.quickMeetingRoomTitle = res.data.title;
          this.QuickMeetingProps.nickname = this.nickname;
          this.QuickMeetingProps.start_time = this.$Moment(
            res.data.start_time * 1000
          ).format("yyyy-MM-DD HH:mm");
          this.QuickMeetingProps.is_camera = false;
          this.QuickMeetingProps.is_speech = false;
          this.QuickMeetingProps.waiting_room = res.data.waiting_room;
          this.QuickMeetingProps.user_id = res.data.user_id;
          this.QuickMeetingdialogOptions.visible = true;
        } else {
          this.$message({
            type: "warning",
            message: "加入等候室会议失败",
          });
        }
      });
    },
    onInviteClose() {
      this.intervalId && clearInterval(this.intervalId);
    },
    getWaitRoomList(item) {
      const params = {
        number: item.number,
      };
      meetingWaitingArea(params)
        .then((res) => {
          console.log("🚀 ~ meetingWaitingArea ~ res:", res);
          // const list = [];
          // for (let i = 0; i < 20; i++) {
          //   list.push(res.data[0]);
          // }
          // this.list = list;
          this.waitRoomlist = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 入会
    onJoin(item) {
      console.log("🚀 ~ onJoin ~ item:", item)
      this.intervalId && clearInterval(this.intervalId);

      console.group("入会信息", item.user_id, this.user_id);
      // let urlList = item.url.split("/");
      // const roomNumber = urlList[urlList.length - 1];
      const roomNumber = item.number;
      const params = {
        number: roomNumber,
        nickname: item.founder_nickname,
        is_camera: 2,
        is_speech: 2,
      };
      joinMeeting(params).then((res) => {
        console.log("🚀 ~ joinMeeting ~ res:", res);
        if (item.waiting_room && item.user_id != this.user_id) {
          // 被邀请并开启等候室
          this.waitingRoomdialogOptions.visible = true;
          this.waitingRoomdialogOptions.title = item.title + "等候室";
          this.waitingRoomdialogOptions.number = item.number;
          this.waitingRoomdialogOptions.meetInfo = item;
          this.getWaitRoomList(item);

          this.intervalId = setInterval(() => {
            this.getWaitRoomList(item);
          }, window.g.intervalTime || 3000);
          return;
        }

        if (res.data.number && res.data.title) {
          this.QuickMeetingProps.quickMeetingRoomNumber = res.data.number;
          this.QuickMeetingProps.quickMeetingRoomTitle = res.data.title;
          this.QuickMeetingProps.nickname = this.nickname;
          this.QuickMeetingProps.start_time = this.$Moment(
            res.data.start_time * 1000
          ).format("yyyy-MM-DD HH:mm");
          this.QuickMeetingProps.is_camera = false;
          this.QuickMeetingProps.is_speech = false;
          // this.QuickMeetingProps.waiting_room =
          //   res.data.waiting_room == 1 ? true : false;
          this.QuickMeetingProps.waiting_room = res.data.waiting_room;
          this.QuickMeetingProps.user_id = res.data.user_id;
          this.QuickMeetingdialogOptions.visible = true;
          console.log("🚀 ~ joinMeeting ~  this.QuickMeetingProps:",  this.QuickMeetingProps)
        } else {
          this.$message({
            type: "warning",
            message: "加入会议失败",
          });
        }
      });
    },
    // 预定会议
    predetermineMeeting() {
      console.log("预定会议");
      this.predetermineMeetingdialogOptions.title = "预定会议";
      this.predetermineMeetingdialogOptions.visible = true;
    },
    // 修改预定会议
    onEditMeeting(item) {
      this.currentRow = item;
      this.predetermineMeetingdialogOptions.title = "修改预定会议";
      this.predetermineMeetingdialogOptions.visible = true;
    },
    // 预定会议弹窗关闭
    onYDClose(form) {
      if (form) {
        meetingAdd(form).then((res) => {
          this.currentRow = null;
          this.predetermineMeetingdialogOptions.visible = false;
          this.getYDMeeting();
        });
      } else {
        this.currentRow = null;
        this.predetermineMeetingdialogOptions.visible = false;
      }
    },
    // 编辑预定会议关闭
    onEditClose(form) {
      if (form) {
        meetingEdit(form).then((res) => {
          this.currentRow = null;
          this.predetermineMeetingdialogOptions.visible = false;
          this.getYDMeeting();
        });
      } else {
        this.currentRow = null;
        this.predetermineMeetingdialogOptions.visible = false;
      }
    },
    // 快速会议
    quickMeeting() {
      console.log("快速会议");
      quickMeeting().then((res) => {
        if (res.data.number && res.data.title) {
          this.QuickMeetingProps.quickMeetingRoomNumber = res.data.number;
          this.QuickMeetingProps.quickMeetingRoomTitle = res.data.title;
          this.QuickMeetingProps.time = this.$Moment(
            res.data.start_time * 1000
          ).format("yyyy-MM-DD HH:mm:ss");
          this.QuickMeetingProps.is_camera = false;
          this.QuickMeetingProps.is_speech = false;
          this.QuickMeetingProps.user_id = res.data.user_id;
          this.QuickMeetingProps.waiting_room =
            res.data.waiting_room == 1 ? true : false;
          this.QuickMeetingdialogOptions.visible = true;
        } else {
          this.$message({
            type: "info",
            message: "创建失败",
          });
        }
      });
    },
    onKSClose() {
      this.QuickMeetingdialogOptions.visible = false;
    },
    // 历史会议
    HistoryMeeting() {
      this.$router.push("/main-layout/history");
      console.log("历史会议");
    },
    // 结束会议
    onEndMeeting(item) {
      endMeeting1({ id: item.id }).then((res) => {
        this.getJXMeeting();
      });
    },
    // 取消会议
    onDel(item) {
      meetingDel({ id: item.id }).then((res) => {
        this.getYDMeeting();
      });
    },
    // 分享会议
    onInvite(item) {
      this.currentRow = item;
      this.shareMeetingdialogOptions.visible = true;
      // this.$alert("这是一段内容", "标题名称", {
      //   confirmButtonText: "确定",
      //   callback: (action) => {
      //     this.$message({
      //       type: "info",
      //       message: `action: ${action}`,
      //     });
      //   },
      // });
    },
    // 关闭分享弹窗
    onFXClose() {
      this.currentRow = null;
      this.shareMeetingdialogOptions.visible = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 重置
    reset() {
      this.form.meeting_name = "";
      this.form.meeting_number = "";
      this.form.promoter = "";
      this.getData();
    },
    getData() {
      // 搜索

      // // 获取进行中会议列表
      // this.getJXMeeting();
      // // 获取预定会议列表
      // this.getYDMeeting();
      this.getStatusList();
      console.log("搜索");
    },
  },
};
</script>

<style lang="scss">
.home-box {
  width: 100%;
  height: 100%;
  // padding: px2vh(20);
  display: flex;

  .home-left-box {
    height: 100%;
    // background-color: pink;
    width: px2vw(320);
    margin-right: px2vh(20);
    background: rgba(55, 65, 81, 0.5);
    border-radius: 8px;
    border: 1px solid rgba(75, 85, 99, 0.5);
    padding: px2vh(20);
    box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    .tltle-box {
      width: 100%;
      text-align: right;
      display: flex;
      align-items: center;
      font-size: 14px;

      .refresh-ipt {
        width: 0;
        flex: 1;
        height: 30px;
        .el-input__inner {
          height: 100%;
        }
      }

      .refresh-box {
        width: px2vh(40);
        height: px5vh(40);
      }
    }

    .tree-box {
      height: 0;
      flex: 1;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.3);
    }

    // 树插槽样式
    .custom-tree-node {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    // 树结构
    .el-tree {
      background: transparent;
      height: 100%;
      color: #cbe0ff;
      font-size: 0.16rem;

      .el-tree-node__content {
        height: 40px;
      }

      &.el-tree--highlight-current
        .el-tree-node.is-current
        > .el-tree-node__content {
        color: rgba(64, 141, 255, 1);

        background: rgba(255, 255, 255, 0.1);
      }

      .el-tree-node__content:hover {
        color: rgba(64, 141, 255, 1);
        background: rgba(255, 255, 255, 0.1);
      }

      .el-tree-node:focus > .el-tree-node__content {
        background: transparent;
      }
    }
  }

  .home-right-box {
    width: 0;
    flex: 1;

    // background: pink;
    // padding: px2vh(20);
    box-sizing: border-box;
    @include flex(column, center, center);

    .top-box {
      width: 100%;
      height: px2vh(240);
      margin-bottom: px2vh(20);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .child {
        width: 30%;
        height: 100%;
        // margin-right: 20px;
        background: rgba(64, 158, 255, 1);
        @include flex(column, center, center);
        border-radius: 8px;
        border: 1px solid rgba(75, 85, 99, 0.5);
        box-sizing: border-box;
        cursor: pointer;

        img {
          width: px2vh(40);
          height: px2vh(40);
          margin-bottom: px2vh(20);
        }
        span {
          @include font4vh(#fff, 20, 400);
        }
      }
      .last-child {
        margin-right: 0;
      }
    }

    .bottom-box {
      width: 100%;
      flex: 1;
      height: 0;
      background: rgba(55, 65, 81, 0.5);
      border-radius: 8px;
      border: 1px solid rgba(75, 85, 99, 0.5);
      padding: px2vh(20);
      box-sizing: border-box;
      @include flex(column, flex-start, flex-start);

      .bottom-title {
        width: 100%;
        @include font4vh(#fff, 24, 400);
        margin-bottom: px2vh(10);
        display: flex;
        justify-content: space-between;

        .history-btn-box {
          font-size: 0.2rem;
          color: #fff;
          padding: px2vh(10) px2vh(20);
          background: #409eff;
          border: 1px solid rgba(75, 85, 99, 0.5);
          box-sizing: border-box;
          border-radius: 0.1rem;
          cursor: pointer;
        }
      }

      .search-box {
        margin-bottom: px2vh(10);
      }

      .bottom-main-box {
        height: 0;
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;

        .bottom-left-box,
        .bottom-right-box {
          height: 100%;
          width: 50%;
          @include flex(column, flex-start, center);

          .small-title-box {
            width: 90%;
            text-align: left;
            margin-bottom: px2vh(20);
          }

          .items-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: scroll;

            .item-box {
              width: 90%;
              padding: px2vh(30);
              border-radius: 0.1rem;
              margin-bottom: px2vh(30);
              background: #fff;
              color: #000;
              // min-height: 1.7rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .item-top-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: px2vh(20);

                .item-title-box {
                  color: rgba(0, 0, 0, 0.76);
                  font-size: 0.2rem;
                  font-weight: 600;
                  width: 70%;
                  @include flex(row, flex-start, center);

                  .title-edit-img {
                    width: 0.2rem;
                    height: 0.2rem;
                    margin-left: 0.2rem;
                    cursor: pointer;
                  }
                }

                .item-status-box {
                  padding: px2vh(6) px2vh(10);
                  color: rgba(148, 191, 255, 1);
                  font-size: 0.14rem;
                  border: 1px solid #94bfff;
                  border-radius: 0.02rem;
                }
                .item-status1-box {
                  padding: px2vh(6) px2vh(10);
                  // color: rgba(148, 191, 255, 1);
                  color: #fff;
                  font-size: 0.14rem;
                  border: 1px solid #94bfff;
                  background: #2d8cff;
                  border-radius: 0.02rem;
                }
              }
              .item-center-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: px2vh(20);
                font-size: 0.14rem;
                color: rgba(0, 0, 0, 0.52);
              }
              .item-btn-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 px2vh(50);
                box-sizing: border-box;
              }
            }
          }

          .no-meet-img {
            width: px2vh(106);
            height: px2vh(106);
            margin-bottom: px2vh(20);
          }
          .no-meet-text {
            @include font4vh(rgba(158, 158, 158, 1), 18, 400);
          }
        }

        .el-divider--vertical {
          height: 80%;
          background-color: rgba(216, 216, 216, 0.5);
        }

        // .bottom-right-box {
        //   height: 100%;
        //   width: 50%;
        //   @include flex(column, center, center);

        //   img {
        //     width: px2vh(106);
        //     height: px2vh(106);
        //     margin-bottom: px2vh(20);
        //   }
        //   span {
        //     @include font4vh(rgba(158, 158, 158, 1), 18, 400);
        //   }
        // }
      }
    }
  }
}
// 弹窗
.tree-popper {
  @include flex(column, center, center);
  border: none;
  background: rgba(55, 65, 81, 1);
  min-width: 50px;

  .popover-content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .el-button {
    margin-left: 0;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}
</style>
